/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
@import '../variable';

/*******************
/*Top bar
*******************/

.topbar {
    background: $topbar;
    .navbar-header{
        /*background: rgba(0, 0, 0, 0.05);*/
        background: #FFF;
    }

    .top-navbar .navbar-header .navbar-brand .dark-logo{
        display: none;
    }
    .top-navbar .navbar-header .navbar-brand .light-logo{
        display: inline-block;
        color:rgba(255, 255, 255, 0.8);
    }
    .navbar-light .navbar-nav .nav-item > {
        a.nav-link {

            color: $themecolor-alt!important;
            &:hover,
            &:focus {
                 /*color: rgba(255, 255, 255, 0.8)!important;*/
                color: $themecolor!important;
            }
        }
    }

    .navbar-light .navbar-nav .language-dropdown > {
        a.nav-link{
            color: $secondary!important;
            &:hover,
            &:focus {
                color: $dark!important;
            }
        }
    }
}


/*******************
/*General Elements
*******************/

a.link {
    &:hover,
    &:focus {
        color: $themecolor!important;
    }
}

.bg-theme {
    background-color: $themecolor !important;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
    background-color: $themecolor !important;
    border-color: $themecolor !important;
}

.right-sidebar {
    .rpanel-title {
        background: $themecolor;
    }
}

.stylish-table {
    tbody tr {
        &:hover,
        &.active {
            border-left: 4px solid $themecolor;
        }
    }
}
.text-themecolor{
    color:$themecolor!important;
}
.profile-tab,
.customtab {
    li {
        a.nav-link {
            position: relative;
            &.active {
                border-bottom: 2px solid $themecolor;
                color: $themecolor;
            }
            &:hover {
                color: $themecolor;
            }
        }
    }
}

.page-link:hover{
    background: $themecolor !important;
    color: $white !important;
    border: 1px solid $themecolor !important;
}

.page-link{
    height: 42px !important;
    line-height: unset !important;
    padding: .5rem .75rem !important;
}

/*******************
/*Buttons
*******************/

.btn-themecolor,
.btn-themecolor.disabled {
    background: $themecolor;
    color: $white;
    border: 1px solid $themecolor;
    &:hover {
        background: $themecolor;
        opacity: 0.7;
        border: 1px solid $themecolor;
    }
    &.active,
    &:focus {
        background: $themecolor-dark;
    }
}


/*******************
/*sidebar navigation
*******************/

.sidebar-nav {
    background: $sidebar;
    padding-right: 0;
    ul {
        li {
            a {
                color: $sidebar-text;
                &.active,
                &:hover {
                    color: $themecolor-alt;
                }
                &.active {
                    color: $dark;
                }
                &.disabled {
                    opacity: 0.5;
                    pointer-events: none;
                    cursor: default;
                    i {
                        color: $muted;
                    }
                }
            }
            &.nav-small-cap {
                color: $muted;
                font-size: 16px;
                margin-bottom: 5px;
            }
        }
    }
    > ul > li {
        &.active > a, &.active:hover > a {

            color: $white;
            background: $themecolor-alt;
            i {
                color: $white;
            }
            &:after{
                border-color:$white;
            }
        }
    }
    > ul > li > a {

        &.active,
        &:hover {

            i {
                color: $themecolor-alt;
            }
        }
        i {
            color: $sidebar-icons;
        }
        &.active {
            font-weight: 400;
            background: $white;
            color: $themecolor;
        }
    }
}

/*******************
/*User profile section
******************/

.user-profile {
    .profile-text {
        padding: 0px;
    }
}

/*******************/
/*CompanyStructure management */
/*******************/
.riskBtn{
    float:right;
    border:none;
    background: transparent;
    font-size: 0.9em;
    color: #455A64;
}

.riskBtn:hover{
    cursor:pointer;
}

.p0{
    padding:0;
}

/*******************/
/*Log management */
/*******************/
.css-filterDiv{
    display: flex;
}
.css-lengthFilter p, .css-lengthFilter select{
    float:left;
}

.css-lengthFilter p, .css-logFilters p{
    margin: 5px 0 0 0 !important;
}

.css-lengthFilter select{
    width: 65px;
    margin: 0 1%;
}

.css-logFilters{
    float:right;
    padding:0;
}

.css-logFilters select{
    width: 75% !important;
}

.css-logFilters p{
    float:left;
    padding:0 2% 0 0;
}

.dataTables_empty{
    text-align: center;
}

.paginate_button.previous{
    border-radius: 5px 0 0 5px;
}

.paginate_button.next{
    border-radius: 0 5px 5px 0;
}

.paginateCenter{
    float: none !important;
    width:fit-content;
    margin: 0 auto;
}

.panel-heading.panel-accordion-heading{
    padding: 8px 15px;
}

.panel-heading a{
    color: #FFF;
}

.panelDiv{
    border: 1px solid $accordionColor;
    background-color: $accordionColor;
    border-radius: 4px;
    margin-bottom: 5px;
}

.panelBody{
    padding: 15px;
    background-color: #FFF;
    border-radius: 0 0 4px 4px
}

.smallTitle{
    font-size: 16px !important;
    margin-bottom: 0;
}

.defaultIcon{
    color: $primary;
}

.greenIcon{
    color: #A7CD39;
}

.redIcon{
    color: #EA1E23;
}

.orangeIcon{
    color: #F57B23;
}

/*******************/
/*CompanyStructure management table */
/*******************/

.scrollTable{
    max-height: 300px;
    overflow-y:scroll;
    overflow-x:hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollTable::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE and Edge */
.scrollTable {
    -ms-overflow-style: none;
}

#jstree{
    color: #505050;
}

#backToSelectedNode, #saveNode, #scrollToTop, .css-exportResultsAsExcel, .css-exportResultsAsPdf{
    background-color: #fff;
    color: $themecolor-alt;
    display: none;
    float: left;
    font-size: 1.2em;
    border-radius: 50%;
    font-weight: bold;
    padding: 9px 10px 6px 6px;
    position: fixed;
    text-align: center;
    right: 5px;
    text-decoration: none;
    width: 40px;
    height: 40px;
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1), 0 3px 20px 0 rgba(231, 224, 213, 0.35);
    z-index: 50;
}

.css-exportResultsAsExcel{
    bottom: 25px;
    font-size: 1.5em !important;
    padding: 3px 7px 3px 7px;
}

.css-exportResultsAsPdf{
    bottom: 70px;
    padding: 3px 8px 3px 7px;
    font-size: 1.5em;
}

#backToSelectedNode{
    bottom: 25px;
    padding: 4px 9px 9px 7px;
    font-size: 1.5em;
}

#scrollToTop{
    bottom: 70px;
    padding: 3px 9px 9px 8px;
    font-size: 1.5em;
}

#saveNode{
    bottom: 115px;
    padding: 7px 0px 9px 0;
}

.jstree-default .jstree-wholerow-clicked{
    background: $primary-opacity !important;
}

.jstree-default .jstree-wholerow-clicked::before{
    content: '•';
    padding-left: 8px;
    color: #FFF;
}

.jstree-container-ul {
    max-width: 100%;
}

/*profile*/

.detail-nav .heading {
    background: $themecolor-alt;
    border-radius: 4px 4px 0 0;
    -webkit-border-radius: 4px 4px 0 0;
    padding: 30px;
    text-align: center;
}

.detail-nav .heading h1 {
    font-size: 22px;
    color: #fff !important;
    margin-bottom: 10px !important;
}

.detail-nav .heading p {
    font-size: 14px;
    color: #fff !important;
}

.detail-nav ul > li {
    width: 100% !important;
    border-bottom: 1px solid #ebeae6;
    margin-top: 0;
    border-left: 5px solid #FFF;
    line-height: 30px;
    padding: 5px 0;
}

.detail-nav ul > li:last-child {
    border-bottom: none;
    border-radius: 0 0 5px 5px;
}

.detail-nav ul > li > a{
    border-radius: 0;
    width:100% !important;
    display: block;
    -webkit-border-radius: 0;
    color: #89817f;
    border-left: 5px solid #fff;
}

.detail-nav ul > li:hover, .detail-nav ul > li:hover{
    border-left: 5px solid $themecolor-alt;
    color: #89817f !important;
}

.detail-nav ul> li{
    width: 100% !important;
    background-color: #FFF;
}

.detail-nav ul > li:last-child > a:last-child {
    border-radius: 0 0 4px 4px;
    -webkit-border-radius: 0 0 4px 4px;
}

.detail-nav ul > li > a > i{
    font-size: 16px;
    padding-right: 10px;
    color: #bcb3aa;
}

.nav.nav-pills.nav-stacked.labels-info p {
    margin-bottom: 0;
    padding: 0 22px;
    color: #9d9f9e;
    font-size: 11px;
}

.nav-pills li:hover {
    background: #f8f7f5 !important;
    border-left: 5px solid #ff766c;
    color: #89817f !important;
    cursor: pointer;
}

.searchBtn{
    background-color: $themecolor-alt;
    color: #FFF !important;
    border-radius: 5px 0 0 5px;
    font-size: unset;
}

.saveBtn{
    background-color: $themecolor-alt;
    color: #FFF !important;
    border-radius:0 5px 5px 0;
    font-size: unset;
    height: 30px !important;
    padding: 3px 10px;
}

.txtBeneficiaryAmount{
    height: 30px !important;;
}

.editAmountBeneficiary .input-group{
    width: 150px;
}

.css-lengthFilter{
    float: right;
    padding-right: 15px !important;
}

.css-lengthFilter select{
    margin: 0 5px;
    padding: 0px 9px;
}

.css-lengthFilterContainer{
    display:flex;
    justify-content: flex-end;
    align-items: center;

    div{
        margin: 0;
        display:flex;
        align-items:center;
    }

    label{
        margin: 0;
    }
}

.dataTables_paginate {
    float: none !important;
    text-align: center !important;
}

@media only screen and (max-width: 992px) {
    .css-lengthFilter{
        float: left;
    }
}

.dropify-wrapper{
    border: 1px solid #ced4da !important;
    border-radius: .25rem;
    height: 75px !important;
}

.dropify-message p{
    text-align: center !important;
}

.dropify-wrapper .dropify-preview .dropify-infos
{
    background-color: $themecolor-alt !important;
}

.dropify-wrapper:hover{
    background-image: none !important;
}

.dropify-wrapper .dropify-preview .dropify-infos .dropify-infos-inner p.dropify-infos-message::before{
    height: 0 !important;
}

.file-icon, .dropify-extension, .dropify-font-file{
    color: $themecolor-alt !important;
}

.dropify-error{
    display:none !important;
}

.dropify-download {
    text-decoration: none;
    display: none;
    position: absolute;
    opacity: 0;
    z-index: 7;
    top: 10px;
    right: 90px;
    background: none;
    border: 2px solid #FFF;
    text-transform: uppercase;
    font-family: "Roboto", "Helvetica Neue", "Helvetica", "Arial";
    font-size: 11px;
    padding: 4px 8px;
    font-weight: bold;
    color: #FFF;
    -webkit-transition: all 0.15s linear;
    transition: all 0.15s linear;
}

.dropify-wrapper.has-preview .dropify-download {
    display: block;
    opacity: 1;
}

.dropify-wrapper .dropify-download:hover {
    background: rgba(255, 255, 255, 0.2);
    color: #FFF !important;
}

.dropify-wrapper .dropify-preview .dropify-infos .dropify-infos-inner{
    top: 60% !important;
}

.dropify-wrapper .dropify-preview .dropify-infos .dropify-infos-inner p.dropify-filename{
    text-align: left !important;
}

.dropify-wrapper .dropify-preview .dropify-infos .dropify-infos-inner p.dropify-infos-message {
    margin-top: 0 !important;
    padding-top: 5px !important;
    text-align: left !important;
}

/** FRONT END **/

.bold{
    font-weight: $global-weight-medium;
}

.title-bar-mobile.title-bar{
    background:$white;
    .logo{
        display:inline-block;
        width:200px;
    }
    .menu-icon::after{
        background:$black;
        -webkit-box-shadow: 0 7px 0 $black, 0 14px 0 $black;
        box-shadow: 0 7px 0 $black, 0 14px 0 $black;
    }
}
.top-bar{
    padding:1rem 0 !important;
    a{
        font-weight: 500;

    }
    .menu-text{
        padding:0 1rem 0 0 !important;

        .logo{
            width:220px;
            height:40px;
            display: inline-block;
            padding:0 !important;
        }
    }

}
.hero{
    background:$light-gray;
    padding-top:3rem;
    overflow: hidden;
    .hero-title{
        font-weight: $global-weight-bold;
        line-height: 1.1;
        font-size:2.5rem;
    }

    .button{
        font-size:1.2rem !important;
        font-weight: $global-weight-medium;
        padding:0.85em 3em;
    }
    .hero-description{
        a{
            font-weight: 700;
            text-decoration: underline;
        }
    }
}
.presentation{
    padding:5em 0;
    border-bottom:1px solid $light-gray;
}
.text-center{
    text-align: center !important;
}
.text-center p{
    text-align: center !important;
}
.btn.disabled, .btn:disabled {
    opacity: .50 !important;
}
.section-title{
    font-weight: $global-weight-bold;
    line-height: 1.1;
    font-size:2.5rem;
}
.play-video{
    display:block;
    position:relative;


    .play-btn{
        display: block;
        width: 80px;
        height: 80px;
        background: #ffd25e;
        position: absolute;
        z-index: 10;
        border-radius: 50%;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        text-align: center;

        .triangle{
            display: block;
            width: 0;
            height: 0;
            border: inset 14px;
            content: '';
            border-right-width: 0;
            border-left-style: solid;
            border-color: transparent transparent transparent #fff;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            position: absolute;
            margin: auto;
            display: block;
        }
    }

}

.advantage-box{
    padding:1em 1.5em;
    .advantage-icon{
        i{
            font-size:3rem;
        }
    }
    h3{
        font-size:1.3rem;
    }
}

.advantageBoxPadding{
    padding-top: 3em;
}

.company-users{
    background: $light-gray;
    padding:5em 0;
}
.grayscale{
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+, Firefox on Android */
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
}
.mb2{
    margin-bottom:2em;
}
.mt2{
    margin-top:2em;
}
.white{
    color:$white;
}
.button.large{
    font-size:1.2rem !important;
    font-weight: $global-weight-medium;
    padding: 0.85em 3em;
}
.safe{
    padding:5em 0 4em 0;
    //background:url(../images/safe-bg.png) no-repeat center center $black;
}

.companyLogo{
    width: 200px;
}
#footer{
    background:$white;

    .footer-top{
        background:$white;
        padding:5rem 0;
        .small{
            font-size:0.8rem;
            font-weight: $global-weight-medium;
        }

        .f-menu{
            a{
                font-weight: $global-weight-bold;
                display:block;
                font-size:0.85rem;
                padding:0.25rem 0;
            }
        }
    }
    .footer-bottom{
        background:$light-gray;
        padding:1em 0;

        .copyright,.powered-block{
            font-size:0.8rem;
        }
        .powered-by{
            display:inline-block;
            color:#000;

            .red{
                color:red;
            }
        }
    }
}

.button.secondary{
    background-color: #ffd25e;
    color: #505b66;
    font-size: 0.9rem;
    font-weight: bold;
    border-radius: .25rem;
}

.button.secondary:hover{
    background-color: #ffbe18;
    color: #505b66;
}

.button.large {
    font-size: 1.2rem !important;
    font-weight: 500 !important;
    padding: 0.85em 3em !important;
}

.menu a, .menu .button {
    line-height: 1;
    text-decoration: none;
    display: block;
    padding: 0.7rem 1rem;
}

#footer {
    background: #fff
}

#footer .footer-top {
    background: #fff;
    padding: 2rem 0;
}

@media print, screen and (max-width: 39.99875em) {
    #footer .footer-top {
        padding: 2em 0
    }

    .section-title{
        font-size: 1.8rem;
    }

    .safe {
        padding-top: 4em;
    }
}

@media print, screen and (max-width: 39.99875em) {
    #footer .footer-top .logo-cell {
        width: 100%
    }
}

@media print, screen and (max-width: 39.99875em) {
    #footer .footer-top .logo-cell img {
        max-width: 200px;
        margin-bottom: 2em
    }
}

#footer .footer-top .small {
    font-size: 0.8rem;
    font-weight: 500
}

#footer .footer-top .f-menu a, .footerLink {
    font-weight: bold;
    display: block;
    font-size: 0.85rem;
    padding: 0.25rem 0;
    text-decoration: none;
    color: #505b66;
}

.logo-cell{
    margin: auto;
}

.downloadIcons{
    width: 150px;
}

.footerLogo{
    width: 250px;
    float:left;
}

.footerLink{
    float:left;
    margin-left: 15px;
}

.footerIcons{
    float:right;
    margin-left: 15px;
}

.css-footerLinks{
    vertical-align: middle;
    line-height: 38px;
}

#userRequestModal .modal-header h4{
    font-weight: bold;
    line-height: 1.1;
    font-size: 1.5rem;
    color: #455a64;
}

.css-submitBtn{
    border:none;
    text-decoration: none;
    background-color: #ffd25e;
    color: #505b66 !important;
    font-size: 0.9rem;
    font-weight: bold;
    box-shadow: none !important;
}

.css-closeBtn:hover{
    background-color: #444d57;
}

.css-submitBtn:hover{
    background-color: #ffbe18;
    border:none !important;
}

.css-closeBtn{
    background-color: #505b66;
    color: #FFF;
    border:none;
    text-decoration: none;
    font-size: 0.9rem;
    font-weight: bold;
}

.hero .downloadIcons{
    padding-bottom: 2%;
}

.company-users{
    overflow: hidden;
}

.logoContainer{
    padding: 0 .625rem;
}

@media only screen and (max-width: 885px) {
    .css-footerLinks{
        vertical-align: unset;
        line-height: unset;
    }
}

@media only screen and (max-width: 768px) {
    .css-downloadBtns{
        margin: auto;
        padding-top: 2%;
    }

    .css-footerLinks{
        margin: auto;
    }

    .footerLink:first-child{
        margin-top: 2%;
    }

    .footerIcons, .footerLogo, .footerLink{
        float: unset;
    }

    .advantageBoxPadding{
        padding-top: 2em;
    }

}


@media only screen and (max-width: 400px) {
    .footerIcons{
        padding: 2% 0;
    }

}

.header img{
    width: 240px;
}

.css-contactBtn{
    float:right;
}

.header{
    padding: 1rem 0;
}

.css-successParagraph{
    display: contents;
    margin:0 !important;
    float:left;
}

.paginationDiv{
    width: fit-content;
    margin: 0 auto;
}

table th a{
    color: unset !important;
}

.css-detailLink{
    text-decoration: none !important;
    color:$white !important;
}

.smallSearchBar{
    width: 95%;
    float:left;
}

.filterBtn{
    padding: 8px 12px !important;
}

.marginIndexTable{
    margin-top: 4rem !important;
}

.marginTable{
    margin-top: 2.5rem !important;
}

.no-results{
    display: none !important;
}

[type="checkbox"]:checked + label:before {
    border-right: 2px solid $themecolor;
    border-bottom: 2px solid $themecolor;
}

.toggleLink{
    color: unset;
    width: 100%;
    display: block;
}

.toggleLink:hover{
    color:unset;
}

.css-color-unset{
    color:unset !important;
}

@media only screen and (max-width: 1000px) {
    .smallSearchBar{
        width: 90%;
        float:left;
    }
}

@media only screen and (max-width: 600px) {
    .smallSearchBar{
        width: 85%;
        float:left;
    }
}

@media only screen and (max-width: 380px) {
    .css-contactBtn{
        display: none;
    }

    .header img{
        width: 160px;
    }

}
/* END FRONT END **/

.bootstrap-select .dropdown-toggle{
    background-color: #FFF !important;
    border: 1px solid #ced4da !important;
    color: #67757c !important;
}

.css-iconPosition{
    float:right;
    margin-top: 5px;
}

.flow-step-active{
    background-color: $themecolor !important;
    color: #FFF !important;
    border-radius: 4px 0 0 4px !important;
}

.flow-step-active:hover{
    margin-right: -1px !important;
}

.flow-step-active:hover i{
    margin-right: 1px !important;
}

.css-paddingRight15{
    padding-right: 15px;
}

.css-flow-step-link{
    padding-right: 10px !important;
}

.sidebar-nav ul li.nav-devider{
    margin: 20px 15px 20px 0 !important;
}


/* EXPORT TABLE */
.exportHeader {
    margin-bottom: 20px;
}

.exportContent h2 {
    color: $themecolor;
}

.exportTable{
    border-collapse: collapse;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 40px 0 rgba(0,0,0,.15);
}

.exportTable thead{
    background-color: $themecolor;
    border-radius: 10px 10px 0 0;
    font-weight: bold;
    color: #FFF;
}

.exportTable th{
    padding-top:10px;
    padding-bottom: 10px;
    line-height: 1.4;
}
/* END EXPORT TABLE */

.no-padding{
    padding: 0 !important;
}

.css-flowTitle{
    border-radius: .25rem .25rem 0 0;
    padding: 5px 20px;
    background-color: $themecolor;
}

.css-flowTitle h4{
    color: white !important;
    font-size: 16px;
}

.css-flowTitle h1{
    font-size: 25px;
    color: white !important;
    margin-bottom: .1rem !important;
}

@media (min-width: 768px) {
    .css-flowTitle > .ref-number {
        position: absolute;
        top: 10px;
        right: 15px;
    }
}

.css-flowFormDiv form div{
    margin-bottom: .5rem;
}

.custom-alert{
    border: 1px solid #ffa502;
    background-color: #fff6dd;
    padding: 5px 10px;
    border-radius: 4px;
    border-left: 8px solid #ffa502;
}

.custom-alert .fa-exclamation-triangle{
    color: #ce8500;
    font-size: 16px;
}

.custom-alert .msg{
    padding: 0 10px;
    font-weight: 400;
    color: #ce8500;
}

.custom-alert button{
    color: #ce8500;
}

.full-color-table.full-primary-table{
    border-collapse: collapse;
    border-radius: .25rem;
    overflow: hidden;
}

.full-color-table.full-primary-table thead{
    border-radius: .25rem .25rem 0 0;
}

.full-color-table.full-primary-table tr{
    background-color: #FAFAFA;
    font-size: 14px;
    transition: background-color 200ms;

    &:hover{
        background-color: rgb(252, 193, 193);
    }
    td{
        padding: .5rem .75rem;
        &.buttons{
            display: flex;
            justify-content: flex-end;
            button, 
            a{
                margin-left: 5px;
            }
        }
    }
}

.full-color-table.full-primary-table tr:hover{
    background-color: rgb(250, 243, 243) !important;
    color: unset !important;
}

.full-color-table.full-primary-table th{
    font-weight: 400 !important;
    padding: 0.5rem !important;
    background-color: #d1d1d1 !important;
}

.contentDiv p{
    margin-bottom: .1rem !important;
}

.contentDiv{
    margin-bottom: .8rem !important;
}

.contentDiv p b{
   font-weight: 500 !important;
}

.modalFormGroup form div:not(:first-of-type):not(.col-lg-6){
    margin-top: .8rem;
}

.notification{
    color: white;
    width: 17px;
    height: 17px;
    border-radius: 1rem;
    background-color: $themecolor;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    position: absolute;
    right: 3px;
    top: 10px;
}

#budgetChart{
    max-height: 500px !important;
}
//small form titles
form[name="cost_center_step"],
form[name="budget_allocation_step"],
form[name="cost_center_final_step"],
form[name="budget_allocation_final_step"] {
    > div > label{
        font-weight: 400;
        line-height: 22px;
        font-size: 18px !important;
        margin-bottom: 10px !important;
        color: #455a64 !important;
    }
}
.modal-dialog{
    min-width: 60vw;
    #health_care_professional,
    #health_care_professional_address,
    #health_care_organization,
    #health_care_organization_address,
    form[name="user"] .modal-body > div,
    #user_address,
    #supplier,
    #supplier_address,
    #patient_organization_address{
        > div {
            margin-bottom: 10px;
            label{
                margin-bottom: 3px;
            }
        }
    }
}
.input-small,
.date-addon{
    max-width: 200px;
}
.scientific-duration-container{
    max-width: 500px;
}
form[name="general_info_step"]{
    .alert-info{
        max-width: 500px;
    }
    .detail-info{
        font-size: 13px;
        margin-top: 5px;
    }
}

.locationDiv {
    > div{
    width: 100%;
    // padding: 15px;
    // background-color: #fafafa;
    border-radius: .25rem;
    display: inline-block;
    }
}
.css-flowFormDiv input, .css-flowFormDiv select, .css-flowFormDiv button{
    padding-top: .175rem !important;
    padding-bottom: .175rem !important;
    min-height: unset !important;
}

.css-flowFormDiv label{
    margin-bottom: 0 !important;
    font-size: .95rem !important;
}

.css-flowFormDiv .smallFlowTitle{
    font-size: 1rem !important;
    color: #67757c !important;
}

.css-flowFormDiv .select2-container--default .select2-selection--single{
    height: unset !important;
}

.css-flowFormDiv .select2-container--default .select2-selection--single .select2-selection__rendered{
    line-height: 30px !important;
}

.display-none, .css-beneficiaryCostType{
    display: none !important;
}

.date-addon {
    position: relative;
}

.date-addon .far {
    position: absolute !important;
    color: #000;
    top: 25%;
    right: 18px;
    z-index: 1000;
}


.beneficiariesTable{
    table-layout: fixed !important;
}

.beneficiariesTable .css-smallColumn{
    width: 20% !important;
}

.beneficiariesTable .css-wideColumn{
    width: 30% !important;
}

.css-beneficiaryActions{
    margin-bottom: 5px;
    display: inline-block;
}

.css-borderRadius{
    border-radius: 4px 0 0 4px !important;
}

.datepicker{
    padding-left: .75rem !important;
    z-index: 1000 !important;
}

.amountCell{
    text-align: right !important;
    width: 120px;
    padding-right: 15px !important;
}

th.amountCell{
    text-align: left !important;
}

.totalDiv{
    background-color: #d1d1d1;
    border-radius: .25rem;
    margin: 0;
}

.glyphicon-chevron-up:before {
    font: normal normal normal 14px/1 FontAwesome;
    content: "\f077";
}

.glyphicon-chevron-down:before {
    font: normal normal normal 14px/1 FontAwesome;
    content: "\f078";
}

.topbar {
    z-index: 1030 !important;
}

.css-flowFormDiv .w-50:nth-child(even){
    padding-right:15px;
    clear:both;
}

.css-flowFormDiv .w-50:nth-child(odd){
    padding-left:15px;
}

.css-flowFormDiv .w-33{
    width: 33.33%;
}

.css-flowFormDiv .w-33:first-child{
    padding-right: 15px;
}

.css-flowFormDiv .w-33:nth-child(2n){
    padding-right: 15px;
    padding-left: 15px;
}

.css-flowFormDiv .w-33:nth-child(3n){
    padding-left: 15px;
}

.css-inputGroupPrepend span{
    background-color: $themecolor !important;
    color: #FFF !important;
}
.css-background-success{
    background-color: #36a551 !important;
    color: #FFF !important;
}

.css-menuText{
    top: 5px;
    display: inline-block;
    position: relative;
    width: 70%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-left: 5px;
}

.css-clearBoth{
    clear:both !important;
    float:left;
}


@media only screen and (max-width: 992px) {
.css-flowFormDiv  .w-50, .css-flowFormDiv  .w-33{
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 100% !important;
}
}
form .switch label{
    font-size: 13px !important;
}
.switch label input[type=checkbox]:checked + .disabledLever {
    background-color: #f2f2f2;
}

.switch label input[type=checkbox]:checked + .lever:after {
    background-color: #e8e8e8;
}

/* IMPERSONATION */
.impersonate-info {
    z-index: 2;
    position: fixed;
    border: 1px solid #b6b6b6;
    border-radius: 5px;
    padding: 10px 15px 2px 15px;
    background-color: rgba(255, 255, 255, 0.95);
    right: 20px;
    bottom: 20px;

    a{
        color: #000 !important;
    }

    .btn{
        padding: 2px 5px 2px 5px !important;
        border-radius: 4px !important;
    }

    .impersonate-icon {
        font-size: 1.5em;

        &:hover{
            cursor:pointer;
        }

        i {
            border-radius: 5px;
            border: 1px solid $themecolor;
            padding:4px 5px 1px 4px;
        }
    }

    .css-minimizeIcon:hover{
        cursor:pointer;
    }

    .css-impersonate-exit-icon {
        display: none;
        position: absolute;
        top: 5px;
        right: 10px;
    }

    .css-userTableDiv{
        max-height: 150px;
        overflow-y: scroll;
        display: none;
        padding-left: 15px;
        padding-right: 15px;
    }

    .css-userTableDiv::-webkit-scrollbar {
        width: 5px;
    }

    .css-userTableDiv::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px grey;
        border-radius: 10px;
    }

    .css-userTableDiv::-webkit-scrollbar-thumb {
        background: $themecolor;
        border-radius: 10px;
    }
}

.red{
    color: $themecolor !important;
}

.select2-selection__placeholder {
    color: #000 !important;
}

.select2-dropdown select2-dropdown--below{
    z-index: 3;
}
/* END IMPERSONATION */

/* TOOLTIP COLOR */
.tooltip-main {
    background: rgb(221, 12, 1) !important;
    background: -moz-linear-gradient(90deg, rgb(221, 12, 1) 0%, rgb(221, 12, 1) 100%) !important;
    background: -webkit-linear-gradient(90deg, rgb(221, 12, 1) 0%, rgb(221, 12, 1) 100%) !important;
    background: linear-gradient(90deg, rgb(221, 12, 1) 0%, rgb(221, 12, 1) 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#DD0C01",endColorstr="#7eba02",GradientType=1) !important;
    color:#fff;
}

.tooltip-inner {
    background: rgb(221, 12, 1) !important;
    background: -moz-linear-gradient(90deg, rgb(221, 12, 1) 0%, rgb(221, 12, 1) 100%) !important;
    background: -webkit-linear-gradient(90deg, rgb(221, 12, 1) 0%, rgb(221, 12, 1) 100%) !important;
    background: linear-gradient(90deg, rgb(221, 12, 1) 0%, rgb(221, 12, 1) 100%) !important;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before{
    border-top-color: $themecolor !important;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before{
    border-right-color: $themecolor !important;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before{
    border-bottom-color: $themecolor !important;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before{
    border-left-color: $themecolor !important;
}
/* END TOOLTIP COLOR*/

.select2-container--default .select2-results>.select2-results__options{
    max-height: 300px;
}

/* ADD DROPDOWN ARROW TO SELECT 2 MULTISELECT BOXES */
.select2-selection--multiple .select2-selection__rendered {
    padding-right: 30px !important;
}

.select2-selection--multiple .select2-selection__rendered:after {
    content: "";
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    border-top: 5px solid #333;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}


/* DASHBOARD */
.dashboard-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.doughnutcharts-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

graphs-container {
    width: 100%;
}

.planned-actions-container {
    display: none;
    width: 0;
    height: 550px;
    max-height: 550px;
    overflow: scroll;
}

.planned-action-item {
    display: flex;
}

.planned-action-item-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

@media only screen and (min-width: 992px) {
    .doughnutcharts-container {
        flex-direction: row;
    }

    .dashboard-card {
        width: 33%;
    }

    .planned-actions-container {
        display: block;
        flex-direction: column;
        gap: 1rem;
        width: 25%;
    }

    .graphs-container {
        width: 75%;
    }
}